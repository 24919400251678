import { createSlice } from "@reduxjs/toolkit";
import { logoutUser, setUser } from "@app/store/authSlice";
import { AppState } from "@app/store/store";
import { compareApi } from "@entities/compare";
import {
    loadCompareCountFromLocalStorage,
    saveCompareCountToLocalStorage,
    saveCompareOffersToLocalStorage
} from "@entities/compare/lib";
import { CompareState } from "./types";

const initialState: CompareState = {
    compareCount: 0,
    localOfferIds: []
};

const compareSlice = createSlice({
    name: "compare",
    initialState,
    reducers: {
        setLocalOffers(state, action) {
            state.localOfferIds = action.payload;
            saveCompareOffersToLocalStorage(state.localOfferIds);
        },
        addLocalOffer(state, action) {
            if (!state.localOfferIds.includes(action.payload)) {
                state.localOfferIds.push(action.payload);
                saveCompareOffersToLocalStorage(state.localOfferIds);
            }
        },
        removeLocalOffer(state, action) {
            state.localOfferIds = state.localOfferIds.filter((id) => id !== action.payload);
            saveCompareOffersToLocalStorage(state.localOfferIds);
        },
        clearLocalOffers(state) {
            state.localOfferIds = [];
            saveCompareOffersToLocalStorage(state.localOfferIds);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUser, (state, action) => {
                if (action.payload) {
                    state.compareCount = loadCompareCountFromLocalStorage();
                }
            })
            .addCase(logoutUser, (state) => {
                state.compareCount = 0;
            })
            .addMatcher(compareApi.endpoints.getCompareOffers.matchFulfilled, (state, action) => {
                state.compareCount = action.payload.offers.length;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.addOfferToComparison.matchFulfilled, (state) => {
                state.compareCount += 1;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.removeOfferFromComparison.matchFulfilled, (state) => {
                state.compareCount -= 1;
                saveCompareCountToLocalStorage(state.compareCount);
            })
            .addMatcher(compareApi.endpoints.removeAllOffersComparison.matchFulfilled, (state) => {
                state.compareCount = 0;
                saveCompareCountToLocalStorage(state.compareCount);
            });
    }
});

export const { setLocalOffers, addLocalOffer, removeLocalOffer, clearLocalOffers } =
    compareSlice.actions;

export const selectCompareCount = (state: AppState) => {
    const serverCount = state.compare.compareCount;
    const localCount = state.compare.localOfferIds.length;

    return serverCount > 0 ? serverCount : localCount;
};
export const selectLocalOfferIds = (state: AppState) => state.compare.localOfferIds;
export default compareSlice;