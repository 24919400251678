import { HYDRATE } from "next-redux-wrapper";
import {
    createAction,
    createSlice,
    SliceCaseReducers,
    ValidateSliceCaseReducers
} from "@reduxjs/toolkit";
import path from "path";
import { Brand, Generation, Model } from "@app/store/types";
import { CURRENT_YEAR, START_YEAR_PRODUCTION } from "@shared/config/Date";
import { AppState } from "./store";
const hydrate = createAction<AppState>(HYDRATE);

export interface FormFile {
    url: string;
    filename: string;
}
export interface PostFormState {
    selectedBrand?: Brand;
    selectedModel?: Model;
    selectedGeneration?: Generation;

    selectedStreet?: string;
    selectedGenerationUpdated?: boolean;
    selectedGenerationValid?: boolean;

    setSelectedDrive?: string;

    selectedEngine?: string;
    selectedEngineValid?: boolean;
    selectedEngineUpdated?: boolean;

    selectedDrive?: string;
    selectedDriveUpdated: boolean;
    selectedDriveValid: boolean;

    selectedYear?: number;
    selectedYearUpdated?: boolean;
    selectedYearValid: boolean;
    yearErrMessage?: string;

    selectedMileage?: number;
    mileageUpdated: boolean;
    mileageValid: boolean;

    selectedEnginePower?: number;

    selectedColor?: string;
    selectedColorUpdated?: boolean;
    selectedColorValid?: boolean;

    selectedDescription?: string;
    selectedDescriptionUpdated?: boolean;
    selectedDescriptionValid?: boolean;
    selectedDescriptionErrMessage?: string;

    selectedPrice?: number;
    selectedPriceUpdated?: boolean;
    selectedPriceValid?: boolean;
    selectedPriceErrMessage?: string;

    selectedCity?: string;
    selectedCountry?: string;

    selectedPhone?: string;
    selectedPhoneUpdated?: boolean;
    selectedPhoneValid?: boolean;
    selectedPhoneErrMessage?: string;

    selectedName?: string;
    selectedNameUpdated?: boolean;
    selectedNameValid?: boolean;
    selectedNameErrMessage?: string;

    selectedSellerType?: string;

    selectedBodyType?: string;
    selectedBodyTypeUpdated: boolean;
    selectedBodyTypeValid: boolean;

    selectedPics?: FormFile[];
    selectedOwnersQnt?: number;
    selectedOwnersQntUpdated?: boolean;
    selectedOwnersQntValid?: boolean;
    selectedOwnersQntErrMessage?: string;

    selectedTransmission?: string;
    selectedTransmissionUpdated?: boolean;
    selectedTransmissionValid?: boolean;

    selectedWheel?: string;
    selectedWheelUpdated?: boolean;
    selectedWheelValid?: boolean;

    horsePower?: number;
    horsePowerUpdated?: boolean;
    horsePowerValid?: boolean;
    horsePowerErrMessage?: string;

    vinNumber?: string;
    vinUpdated?: boolean;
    vinValid?: boolean;
    vinErrMessage?: string;

    autoFilled: boolean;
    characteristicsFilled: boolean;
    photoFilled: boolean;
    descriptionFilled: boolean;
    contactsFilled: boolean;
    placeFilled: boolean;
    priceFilled: boolean;

    isFilled: boolean;

    coordinates: number[];
}

const initialState: PostFormState = {
    coordinates: [],
    selectedGenerationUpdated: false,
    selectedGenerationValid: false,
    autoFilled: false,
    characteristicsFilled: false,
    photoFilled: false,
    descriptionFilled: false,
    contactsFilled: false,
    placeFilled: false,
    priceFilled: false,
    isFilled: false,
    selectedPics: [],
    selectedSellerType: "individual",
    selectedYearUpdated: false,
    selectedYearValid: false,

    selectedPriceErrMessage: "Не заполнено",

    selectedOwnersQntErrMessage: "Не заполнено",

    selectedBodyTypeUpdated: false,
    selectedBodyTypeValid: false,

    horsePowerUpdated: false,
    horsePowerValid: false,

    vinUpdated: false,
    vinValid: false,

    mileageUpdated: false,
    mileageValid: false,

    selectedDescriptionUpdated: false,
    selectedDescriptionValid: false,

    selectedPriceUpdated: false,
    selectedPriceValid: false,

    selectedNameUpdated: false,
    selectedNameValid: false,

    selectedPhoneUpdated: false,
    selectedPhoneValid: false,

    selectedColorUpdated: false,
    selectedColorValid: false,

    selectedEngineValid: false,
    selectedEngineUpdated: false,

    selectedDriveUpdated: false,
    selectedDriveValid: false,

    selectedTransmissionUpdated: false,
    selectedTransmissionValid: false,

    selectedWheelUpdated: false,
    selectedWheelValid: false,

    selectedNameErrMessage: "Не заполнено",
    selectedPhoneErrMessage: "Не заполнено",
    selectedDescriptionErrMessage: "Не заполнено"
};

export const postEditFormsReducers: ValidateSliceCaseReducers<
    PostFormState,
    SliceCaseReducers<PostFormState>
> = {
    updateStatus(
        state,
        action: {
            payload: {
                blockName: string;
                isFilled: boolean;
            };
        }
    ) {
        // @ts-ignore
        state[action.payload.blockName] = !!action.payload.isFilled;
        state.isFilled = checkIsFilled(state);
    },
    reset: (state) => {
        state.selectedBrand = undefined;
        state.selectedModel = undefined;

        state.selectedGeneration = undefined;
        state.autoFilled = false;
        state.characteristicsFilled = false;
        state.photoFilled = false;
        state.descriptionFilled = false;
        state.contactsFilled = false;
        state.placeFilled = false;
        state.priceFilled = false;
        state.isFilled = false;
        state.selectedPics = [];
        state.selectedSellerType = "individual";
        state.selectedYear = undefined;
        state.selectedBodyType = undefined;
        state.selectedEngine = undefined;
        state.selectedDrive = undefined;
        state.selectedColor = undefined;
        state.selectedMileage = undefined;
        state.selectedDescription = undefined;
        state.selectedPrice = undefined;
        state.selectedCity = undefined;
        state.selectedCountry = undefined;
        state.selectedPhone = undefined;
        state.selectedName = undefined;
        state.selectedOwnersQnt = undefined;
        state.selectedTransmission = undefined;
        state.selectedWheel = undefined;
    },

    loadDefaultValues: (state, action) => {
        const { payload } = action;

        if (payload.brand) {
            state.selectedBrand = {
                id: payload.brandUuid || "",
                name: payload.brand || ""
            };
        }

        if (payload.model) {
            state.selectedModel = {
                id: payload.modelUuid || "",
                name: payload.model || ""
            };
        }

        if (state.selectedBrand && state.selectedModel) {
            state.autoFilled = true;
        }

        if (payload.generationUuid) {
            state.selectedGeneration = {
                id: payload.generationUuid || "",
                name: payload.generationName || ""
            };
        }

        state.selectedYear = action.payload.year;
        state.selectedBodyType = action.payload.body;
        state.selectedEngine = action.payload.engine;
        state.selectedDrive = action.payload.drive;
        state.selectedTransmission = action.payload.transmission;
        state.selectedWheel = action.payload.wheel;
        state.horsePower = action.payload.horsePower;
        state.selectedColor = action.payload.color;
        state.selectedMileage = action.payload.mileage;
        state.vinNumber = action.payload.vin;

        if (
            state.selectedYear &&
            state.selectedBodyType &&
            state.selectedGeneration &&
            state.selectedEngine &&
            state.selectedDrive &&
            state.selectedColor &&
            state.selectedMileage
        ) {
            state.characteristicsFilled = true;
        }

        state.selectedDescription = action.payload.description;
        state.selectedOwnersQnt = action.payload.ownersQnt;

        if (state.selectedDescription && state.selectedOwnersQnt) {
            state.descriptionFilled = true;
        }

        state.selectedSellerType = action.payload.sellerType || "individual";
        state.selectedName = action.payload.contact;
        state.selectedPhone = action.payload.phone;

        if (state.selectedSellerType && state.selectedName && state.selectedPhone) {
            state.contactsFilled = true;
        }

        state.selectedPrice = action.payload.price;
        if (state.selectedPrice) {
            state.priceFilled = true;
        }

        state.selectedCity = action.payload.city;
        state.selectedCountry = action.payload.country;
        state.selectedStreet = action.payload.address;
        if (state.selectedCity && state.selectedCountry) {
            state.placeFilled = true;
        }

        state.selectedPics = action.payload.pics.map((el: any) => {
            const url = el.bigUrl || el.url;
            return {
                url: url,
                filenam: path.parse(url).base
            };
        });

        state.isFilled = checkIsFilled(state);
    },

    setBrand: (state, action) => {
        const newBrand = action.payload;
        if (!newBrand || !state.selectedBrand || state.selectedBrand.id !== newBrand.id) {
            state.selectedModel = undefined;
            state.selectedGeneration = undefined;
        }
        state.selectedBrand = newBrand;
        state.isFilled = checkIsFilled(state);
    },
    setModel: (state, action) => {
        const newModel = action.payload;
        if (!newModel || !state.selectedModel || state.selectedModel.id !== newModel.id) {
            state.selectedGeneration = undefined;
        }
        state.selectedModel = newModel;
        state.isFilled = checkIsFilled(state);
    },
    setGeneration: (state, action) => {
        state.selectedGeneration = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedEngine: (state, action) => {
        state.selectedEngine = action.payload;
        state.selectedGenerationUpdated = true;
        state.selectedEngineValid = Boolean(state.selectedEngine);
        state.isFilled = checkIsFilled(state);
    },
    setSelectedDrive: (state, action) => {
        state.selectedDrive = action.payload;
        state.selectedDriveUpdated = true;
        state.selectedDriveValid = Boolean(state.selectedDrive);
        state.isFilled = checkIsFilled(state);
    },
    setBodyType: (state, action) => {
        state.selectedBodyType = action.payload;

        state.selectedBodyTypeUpdated = true;
        if (action.payload.toString().length > 0) {
            state.selectedBodyTypeValid = true;
        } else {
            state.selectedBodyTypeValid = false;
        }

        state.isFilled = checkIsFilled(state);
    },
    setYear: (state, action) => {
        const val = action.payload;
        state.selectedYear = val || undefined;
        state.selectedYearUpdated = true;
        if (val && val.toString().length === 4) {
            if (val >= START_YEAR_PRODUCTION && val <= CURRENT_YEAR) {
                state.selectedYearValid = true;
                state.yearErrMessage = "";
            } else {
                state.yearErrMessage = "Некорректное значение";
                state.selectedYearValid = false;
            }
        } else {
            state.yearErrMessage = "Неверный формат года";
            state.selectedYearValid = false;
        }
        state.isFilled = checkIsFilled(state);
    },
    setSelectedColor: (state, action) => {
        state.selectedColorUpdated = true;
        if (action.payload.length > 0) {
            state.selectedColorValid = true;
        } else {
            state.selectedColorValid = false;
        }
        state.selectedColor = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedDescription: (state, action) => {
        state.selectedDescriptionUpdated = true;

        if (action.payload.length === 0) {
            state.selectedDescriptionValid = false;
            state.selectedDescriptionErrMessage = "Не заполнено";
        } else {
            state.selectedDescriptionValid = true;
            state.selectedDescriptionErrMessage = "";
        }

        state.selectedDescription = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedPrice: (state, action) => {
        state.selectedPrice = action.payload;
        state.selectedPriceUpdated = true;

        if (action.payload.toString().length === 0) {
            state.selectedPriceErrMessage = "Не заполнено";
            state.selectedPriceValid = false;
        } else {
            if (action.payload < 100 || action.payload > 500000000) {
                state.selectedPriceErrMessage = "Некорректное значение";
                state.selectedPriceValid = false;
            } else {
                state.selectedPriceErrMessage = "";
                state.selectedPriceValid = true;
            }
        }
        state.isFilled = checkIsFilled(state);
    },
    setSelectedCity: (state, action) => {
        state.selectedCity = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedCountry: (state, action) => {
        state.selectedCountry = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedStreet: (state, action) => {
        state.selectedStreet = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedPhone: (state, action) => {
        state.selectedPhoneUpdated = true;

        if (action.payload.length > 0) {
            if (action.payload.length > 10) {
                state.selectedPhoneValid = true;
                state.selectedPhoneErrMessage = "";
            } else {
                state.selectedPhoneValid = false;
                state.selectedPhoneErrMessage = "Не заполнено полностью";
            }
        } else {
            state.selectedPhoneValid = false;
            state.selectedPhoneErrMessage = "Не заполнено";
        }

        state.selectedPhone = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedName: (state, action) => {
        state.selectedName = action.payload;
        state.selectedNameUpdated = true;
        state.selectedNameErrMessage = "";

        const regexp = new RegExp(/^[a-zA-Zа-яА-Я-]+$/);

        if (!regexp.test(action.payload)) {
            state.selectedNameValid = false;
            state.selectedNameErrMessage = "Недопустимые символы в имени";
        } else {
            if (action.payload.length >= 1) {
                state.selectedNameValid = true;
                state.selectedNameErrMessage = "";
            } else {
                if (action.payload.length === 0) {
                    state.selectedNameValid = false;
                    state.selectedNameErrMessage = "Не заполнено";
                } else {
                    state.selectedNameValid = false;
                    state.selectedNameErrMessage = "Слишком короткое имя";
                }
            }
        }

        state.isFilled = checkIsFilled(state);
    },
    setSelectedSellerType: (state, action) => {
        state.selectedSellerType = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedBodyType: (state, action) => {
        state.selectedBodyType = action.payload;

        state.selectedBodyTypeUpdated = true;
        debugger;
        if (action.payload.toString().length > 0) {
            state.selectedBodyTypeValid = true;
        } else {
            state.selectedBodyTypeValid = false;
        }

        state.isFilled = checkIsFilled(state);
    },
    setSelectedMileage: (state, action) => {
        state.mileageUpdated = true;

        if (action.payload > 0) {
            state.mileageValid = true;
        } else {
            state.mileageValid = false;
        }

        state.selectedMileage = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedPics: (state, action) => {
        state.selectedPics = action.payload;
        state.isFilled = checkIsFilled(state);
    },
    setSelectedOwnersQnt: (state, action) => {
        state.selectedOwnersQnt = action.payload;
        state.selectedOwnersQntUpdated = true;
        if (action.payload > 0) {
            state.selectedOwnersQntValid = true;
            state.horsePowerErrMessage = "";
        } else {
            state.selectedOwnersQntValid = false;
            state.horsePowerErrMessage = "Не заполнено";
        }

        state.isFilled = checkIsFilled(state);
    },
    setSelectedTransmission: (state, action) => {
        state.selectedTransmission = action.payload;

        state.selectedTransmissionUpdated = true;
        if (action.payload.toString().length > 0) {
            state.selectedTransmissionValid = true;
        } else {
            state.selectedTransmissionValid = false;
        }

        state.isFilled = checkIsFilled(state);
    },
    setSelectedWheel: (state, action) => {
        state.selectedWheel = action.payload;
        state.selectedWheelUpdated = true;

        if (action.payload.toString().length > 0) {
            state.selectedWheelValid = true;
        } else {
            state.selectedWheelValid = false;
        }

        state.isFilled = checkIsFilled(state);
    },
    setHorsePower: (state, action) => {
        state.horsePower = action.payload;
        state.horsePowerUpdated = true;
        if (action.payload?.toString().length > 0) {
            if (action.payload < 5 || action.payload > 1000) {
                state.horsePowerErrMessage = "неверное значение";
                state.horsePowerValid = false;
            } else {
                state.horsePowerErrMessage = "";
                state.horsePowerValid = true;
            }
        } else {
            state.horsePowerErrMessage = "не заполнено";
            state.horsePowerValid = false;
        }

        state.isFilled = checkIsFilled(state);
    },
    setVinNumber: (state, action) => {
        state.vinUpdated = true;
        if (action.payload?.toString().length === 17) {
            state.vinValid = true;
            state.vinErrMessage = "";
        } else {
            if (action.payload?.toString().length === 0) {
                state.vinErrMessage = "не заполнен";
                state.vinValid = false;
            } else {
                state.vinErrMessage = "номер должен состоять из 17 символов";
                state.vinValid = false;
            }
        }

        state.vinNumber = action.payload;
    },
    setCoordinates: (state, action) => {
        state.coordinates = action.payload;
    }
};

export const postFormSlice = createSlice({
    name: "postForm",
    initialState,
    reducers: postEditFormsReducers,

    extraReducers: (builder) => {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.postForm
            };
        });
    }
});

export const checkIsFilled = (state: PostFormState) => {
    if (
        state.autoFilled &&
        state.characteristicsFilled &&
        state.photoFilled &&
        state.descriptionFilled &&
        state.contactsFilled &&
        state.placeFilled &&
        state.priceFilled
    ) {
        return true;
    }
    return false;
};

export type EditPostStoreName = "postForm" | "editForm" | "analyticsForm";

export const {
    updateStatus,
    reset,
    setBrand,
    setModel,
    setGeneration,
    setSelectedEngine,
    setSelectedDrive,
    setBodyType,
    setYear,
    setSelectedColor,
    setSelectedDescription,
    setSelectedPrice,
    setSelectedCity,
    setSelectedCountry,
    setSelectedPhone,
    setSelectedName,
    setSelectedSellerType,
    setSelectedBodyType,
    setSelectedMileage,
    setSelectedStreet,
    setSelectedPics,
    setSelectedOwnersQnt,
    setSelectedTransmission,
    setSelectedWheel,
    setHorsePower,
    loadDefaultValues,
    setVinNumber,
    setCoordinates
} = postFormSlice.actions;

export const actions = postFormSlice.actions;

export const selectFilledStatus = (state: AppState) => state.postForm;

export default postFormSlice.reducer;