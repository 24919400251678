import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "@shared/api/getAccessToken";
import { configApi } from "@shared/lib/configApi";
import { UPLOAD_ERROR_MESSAGE } from "./constants";
import { UploadImagesArgs, UploadImagesResponse } from "./types";

export const uploadSupportCarImages = createAsyncThunk<
    UploadImagesResponse,
    UploadImagesArgs,
    { rejectValue: string }
>("support/uploadSupportCarImages", async (files, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
        });

        const response = await fetch(`${configApi.API_URL}/v1/media_object/feedback/cars`, {
            method: "POST",
            body: formData,
            headers: {
                "X-Auth-token": getAccessToken()
            }
        });

        if (!response.ok) {
            throw new Error();
        }

        const data: UploadImagesResponse = await response.json();
        return data;
    } catch {
        toast.error(UPLOAD_ERROR_MESSAGE, { autoClose: 3000 });
        return rejectWithValue(UPLOAD_ERROR_MESSAGE);
    }
});