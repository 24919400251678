import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authSlice } from "./authSlice";
import { createWrapper } from "next-redux-wrapper";
import { profileApi, publicProfileApi } from "@entities/profile";
import { compareApi, compareListenerMiddleware, compareSlice } from "@entities/compare";
import { loginListenerMiddleware } from "@entities/auth";
import { supportApi } from "@entities/support";
import layoutSlice from "./layoutSlice";
import { postFormSlice } from "./postFormSlice";
import { offersApi } from "./offersApi";
import { blogApi } from "./blogApi";
import filtersSlice from "./filtersSlice";
import { filtersOptionsApi } from "./filtersOptionsApi";
import { editFormSlice } from "./editFormSlice";
import { analyticsApi } from "./analyticsApi";
import { analyticsFormSlice } from "./analyticsFormSlice";
import intensionsSlice from "./intensionsSlice";
import { callbackApi } from "./callbackFormApi";
import { docsApi } from "./docsApi";
import localFavoritesSlice from "@entities/offers/api/local-favorites-api";
import favoritesSlice from "@entities/offers/api/favorites-slice";

const makeStore = () =>
    configureStore({
        reducer: {
            [authSlice.name]: authSlice.reducer,
            [layoutSlice.name]: layoutSlice.reducer,
            [postFormSlice.name]: postFormSlice.reducer,
            [editFormSlice.name]: editFormSlice.reducer,
            [analyticsFormSlice.name]: analyticsFormSlice.reducer,
            [offersApi.reducerPath]: offersApi.reducer,
            [blogApi.reducerPath]: blogApi.reducer,
            [profileApi.reducerPath]: profileApi.reducer,
            [publicProfileApi.reducerPath]: publicProfileApi.reducer,
            [filtersSlice.name]: filtersSlice.reducer,
            [filtersOptionsApi.reducerPath]: filtersOptionsApi.reducer,
            [analyticsApi.reducerPath]: analyticsApi.reducer,
            [intensionsSlice.name]: intensionsSlice.reducer,
            [callbackApi.reducerPath]: callbackApi.reducer,
            [compareApi.reducerPath]: compareApi.reducer,
            [compareSlice.name]: compareSlice.reducer,
            [docsApi.reducerPath]: docsApi.reducer,
            [supportApi.reducerPath]: supportApi.reducer,
            [localFavoritesSlice.name]: localFavoritesSlice.reducer,
            [favoritesSlice.name]: favoritesSlice.reducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .prepend(compareListenerMiddleware.middleware)
                .prepend(loginListenerMiddleware.middleware)
                .concat(blogApi.middleware)
                .concat(offersApi.middleware)
                .concat(profileApi.middleware)
                .concat(publicProfileApi.middleware)
                .concat(filtersOptionsApi.middleware)
                .concat(analyticsApi.middleware)
                .concat(callbackApi.middleware)
                .concat(compareApi.middleware)
                .concat(docsApi.middleware)
                .concat(supportApi.middleware),
        devTools: true
    });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export type AppDispatch = ReturnType<typeof makeStore>["dispatch"];
export const wrapper = createWrapper<AppStore>(makeStore);