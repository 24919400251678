export {
    compareApi,
    useGetCompareOffersQuery,
    useAddOfferToComparisonMutation,
    useGetCompareOffersByIdsQuery,
    useUpdateCompareOffersMutation,
    useRemoveOfferFromComparisonMutation,
    useRemoveAllOffersComparisonMutation,
    useMergeOffersComparisonMutation
} from "./api/compareApi";

export { loadCompareOffersFromLocalStorage } from "./lib/localStorageCompare/localStorageCompare";

export { default as compareSlice } from "./model/compareSlice";
export {
    addLocalOffer,
    selectCompareCount,
    selectLocalOfferIds,
    setLocalOffers,
    removeLocalOffer,
    clearLocalOffers
} from "./model/compareSlice";
export { compareListenerMiddleware } from "./model/compareListener";