import { Countries } from "@features/seo/seo.constants";
import { City } from "@shared/lib/interfaces/city.interface";

export const PLATFORMS = [
    { value: 0, label: "Likvi.com", country: Countries.RU },
    { value: 1, label: "Drom.ru", country: Countries.RU },
    { value: 2, label: "Avito.ru", country: Countries.RU },
    { value: 3, label: "Auto.ru", country: Countries.RU },
    { value: 6, label: "Aster.kz", country: Countries.KZ },
    { value: 7, label: "Mycar.kz", country: Countries.KZ },
    { value: 8, label: "Kolesa.kz", country: Countries.KZ },
    { value: 9, label: "Mobile.de", country: Countries.DE },
    { value: 10, label: "Av.by", country: Countries.BY },
    { value: 11, label: "Uremont.com", country: Countries.RU },
    { value: 12, label: "Migtorg.com", country: Countries.RU }
];

export const getCountries = () => {
    const uniqueCountries = new Set(PLATFORMS.map((platform) => platform.country));
    return Array.from(uniqueCountries).map((country) => {
        return {
            value: country,
            label: getCountryNameByCode(country)
        };
    });
};

export const getCountryNameByCode = (code: Countries) => {
    switch (code) {
        case Countries.RU:
            return "Россия";
        case Countries.KZ:
            return "Казахстан";
        case Countries.BY:
            return "Беларусь";
        case Countries.DE:
            return "Германия";
        default:
            return "Россия";
    }
};

export const SourceToCountry: { [key: number]: Countries } = {
    1: Countries.RU,
    2: Countries.RU,
    3: Countries.RU,
    4: Countries.RU,
    5: Countries.RU,
    6: Countries.KZ,
    7: Countries.KZ,
    8: Countries.KZ,
    9: Countries.DE,
    10: Countries.BY,
    11: Countries.RU,
    12: Countries.RU
};

export const OWNERS_QNT = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" }
];

export const TIMER_DURATION = 60;

export const enum Sort {
    Popular = "Популярные",
    Expensive = "Сначала дорогие",
    Cheap = "Сначала дешевые",
    YearNewer = "По году: Новее",
    YearOlder = "По году: Старше",
    MileageLess = "По пробегу: Меньше",
    MileageMore = "По пробегу: Больше",
    ByName = "По названию"
}

export const enum TimeSort {
    Hour = "За час",
    ThreeHours = "За 3 часа",
    Day = "За день",
    Week = "За неделю",
    Month = "За месяц",
    AllTime = "За все время"
}
export const TimeSlots = [
    { value: "За час", label: "За час" },
    { value: "За 3 часа", label: "За 3 часа" },
    { value: "За день", label: "За день" },
    { value: "За неделю", label: "За неделю" },
    { value: "За месяц", label: "За месяц" },
    { value: "За все время", label: "За все время" }
];

export const DefaultTimeSort = {
    created_at: {
        order: "asc"
    }
};

export const getSortObject = (sortType: Sort) => {
    if (sortType === Sort.Popular) {
        return {
            created_at: {
                order: "asc"
            }
        };
    } else if (sortType === Sort.Cheap) {
        return {
            price: {
                order: "asc"
            }
        };
    } else if (sortType === Sort.Expensive) {
        return {
            price: {
                order: "desc"
            }
        };
    } else if (sortType === Sort.YearNewer) {
        return {
            year: {
                order: "desc"
            }
        };
    } else if (sortType === Sort.YearOlder) {
        return {
            year: {
                order: "asc"
            }
        };
    } else if (sortType === Sort.MileageLess) {
        return {
            mileage: {
                order: "asc",
                missing: "_first"
            }
        };
    } else if (sortType === Sort.MileageMore) {
        return {
            mileage: {
                order: "desc"
            }
        };
    } else if (sortType === Sort.ByName) {
        return {
            title: {
                order: "desc"
            }
        };
    } else {
        return {};
    }
};

export interface FilterParams {
    brandValue?: string;
    modelValue?: string;
    generationValue?: string;
    yearFromValue?: number;
    yearToValue?: number;
    engineVolumeFromValue?: number;
    engineVolumeToValue?: number;
    bodyValue?: string;
    transmissionValue?: string;
    engineTypeValue?: string;
    driveValue?: string;
    wheelValue?: string;
    priceFromValue?: number;
    priceToValue?: number;
    mileageFromValue?: number;
    mileageToValue?: number;
    horsePowerFromValue?: number;
    horsePowerToValue?: number;
    ownerQntFrom?: number;
    ownersQntTo?: number;
    citiesValue?: City[];
    sourceId?: number[];
    timeFrom?: string;
    sellerType?: string;
    sort?: string;
    created_at?: string | null;
    cityAlias?: string;
    brandAlias?: string;
    modelAlias?: string;
    generationCode?: number;
    restyling?: number;
    country?: string;
    hideNoImages?: boolean;
}

export const generateFilterObject = (filterParams: FilterParams) => {
    const {
        brandValue,
        modelValue,
        yearFromValue,
        yearToValue,
        engineVolumeFromValue,
        engineVolumeToValue,
        bodyValue,
        transmissionValue,
        engineTypeValue,
        driveValue,
        wheelValue,
        priceFromValue,
        priceToValue,
        mileageFromValue,
        mileageToValue,
        horsePowerFromValue,
        horsePowerToValue,
        generationValue,
        ownerQntFrom,
        ownersQntTo,
        citiesValue,
        sourceId,
        timeFrom,
        sellerType,
        sort,
        created_at,
        cityAlias,
        brandAlias,
        modelAlias,
        generationCode,
        restyling,
        country,
        hideNoImages
    } = filterParams;

    let filters: { [key: string]: any } = {};

    if (brandValue) filters["brandUuid"] = brandValue;
    if (modelValue) filters["modelUuid"] = modelValue;
    if (generationValue) filters["generationUuid"] = generationValue;
    if (yearFromValue) filters["from_year"] = yearFromValue;
    if (yearToValue) filters["to_year"] = yearToValue;
    if (engineVolumeFromValue) filters["from_displacement"] = engineVolumeFromValue;
    if (engineVolumeToValue) filters["to_displacement"] = engineVolumeToValue;
    if (bodyValue) filters["body_type"] = bodyValue;
    if (transmissionValue) filters["transmission_type"] = transmissionValue;
    if (engineTypeValue) filters["engine_type"] = engineTypeValue;
    if (driveValue) filters["drive_type"] = driveValue;
    if (wheelValue) filters["wheel_type"] = wheelValue;
    if (priceFromValue) filters["from_price"] = priceFromValue;
    if (priceToValue) filters["to_price"] = priceToValue;
    if (mileageFromValue) filters["from_mileage"] = mileageFromValue;
    if (mileageToValue) filters["to_mileage"] = mileageToValue;
    if (horsePowerFromValue) filters["from_horse_power"] = horsePowerFromValue;
    if (horsePowerToValue) filters["to_horse_power"] = horsePowerToValue;
    if (ownerQntFrom) filters["from_owners_count"] = ownerQntFrom;
    if (ownersQntTo) filters["to_owners_count"] = ownersQntTo;
    if (citiesValue && citiesValue.length > 0) filters["city"] = citiesValue[0].name;
    if (sourceId && sourceId.length > 0) filters["source_id"] = sourceId;
    if (timeFrom) filters["timeFrom"] = timeFrom;
    if (sellerType) filters["seller_type"] = sellerType;
    if (sort) filters["sort"] = sort;
    if (typeof created_at === "string") filters["created_at"] = created_at;
    if (cityAlias) filters["cityAlias"] = cityAlias;
    if (brandAlias) filters["brandAlias"] = brandAlias;
    if (modelAlias) filters["modelAlias"] = modelAlias;
    if (generationCode) filters["generation"] = generationCode;
    if (restyling) filters["restyling"] = restyling;
    if (country) filters["country"] = country;
    if (hideNoImages) filters["hideNoImages"] = hideNoImages;

    return filters;
};

export const URI_CATALOG = `/catalog`;

export const generateCatalogUri = (
    cities?: City[],
    brand?: string,
    model?: string,
    generationCode?: number,
    restyling?: number,
    filters?: {
        bodyType?: string;
        priceFrom?: number;
        priceTo?: number;
    }
) => {
    let uri = URI_CATALOG;
    const city = cities && cities.length > 0 ? cities[0] : null;

    uri += city ? `/${city.alias}` : `/${ALL_CITIES}`;
    uri += brand ? `/${brand}` : "";
    uri += model ? `/${model}` : "";
    uri += generationCode ? `/generation-${generationCode}` : "";
    if (generationCode) {
        uri += `/restyling-${restyling ?? 0}`;
    }

    uri += `/${FILTERS_URI_POSTFIX}`;

    const queryParams: Record<string, string> = {};

    if (filters?.bodyType) queryParams.body_type = filters.bodyType;
    if (filters?.priceFrom) queryParams.price_from = filters.priceFrom.toString();
    if (filters?.priceTo) queryParams.price_to = filters.priceTo.toString();

    const queryString = new URLSearchParams(queryParams).toString();
    if (queryString) {
        uri += `?${queryString}`;
    }

    return uri;
};

export const AnalyticsFiltersList = [
    "mark",
    "model",
    "year",
    "generation_number",
    "displacement",
    "prep_body_type",
    "prep_engine_type",
    "prep_drive_type",
    "prep_transmission"
];

export const isUriValue = (value: string) => {
    return value !== ALL_CITIES && value !== FILTERS_URI_POSTFIX;
};

export const ALL_CITIES = "all-cities";
export const FILTERS_URI_POSTFIX = "all";

export const MAIN_PAGE_ADBOCK_POSITIONS = [4, 11, 15];
export const CATALOG_PAGE_ADBLOCK_POSITIONS = [4, 11, 15, 28, 35, 41];
export const DEFAULT_PAGE_LIMIT = 42;
export const PAGE_LIMIT_MAIN_SCREEN = 20;