import { COMPARE_COUNT_KEY, COMPARE_OFFERS_KEY } from "./constants";

export const loadCompareCountFromLocalStorage = (): number => {
    if (typeof window !== "undefined") {
        const savedCount = localStorage.getItem(COMPARE_COUNT_KEY);
        return savedCount ? parseInt(savedCount, 10) : 0;
    }
    return 0;
};

export const saveCompareCountToLocalStorage = (count: number) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(COMPARE_COUNT_KEY, count.toString());
    }
};

export const loadCompareOffersFromLocalStorage = (): string[] => {
    if (typeof window !== "undefined") {
        const savedOffers = localStorage.getItem(COMPARE_OFFERS_KEY);
        return savedOffers ? JSON.parse(savedOffers) : [];
    }
    return [];
};

export const saveCompareOffersToLocalStorage = (offers: string[]) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(COMPARE_OFFERS_KEY, JSON.stringify(offers));
    }
};