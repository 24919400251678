import { uuidv7 } from "uuidv7";

const UUID_TOKEN_KEY = "uuid";

export const getDeviceUUID = (): string => {
    if (typeof window !== "undefined") {
        const uuid = localStorage.getItem(UUID_TOKEN_KEY);
        if (!uuid) {
            const newUuid = uuidv7();
            localStorage.setItem(UUID_TOKEN_KEY, newUuid);
            return newUuid;
        }
        return uuid;
    }
    return "";
};