import { createApi } from "@reduxjs/toolkit/query/react";
import {
    PublicCompanyProfile,
    PublicCompanyProfileResponse,
    PublicProfile,
    PublicProfileResponse
} from "@entities/profile/api/types";
import { baseQuery } from "@shared/api/baseQuery";
import { configApi } from "@shared/lib/configApi";

export const publicProfileApi = createApi({
    reducerPath: "publicProfileApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getPublicProfile: builder.query<PublicProfile, string>({
            query: (uuid) => ({ url: `${configApi.API_URL}/v1/profiles/${uuid}` }),
            transformResponse: (response: PublicProfileResponse) => response.data
        }),
        getPublicCompanyProfile: builder.query<PublicCompanyProfile, string>({
            query: (uuid) => ({ url: `${configApi.API_URL}/v1/company/profiles/${uuid}` }),
            transformResponse: (response: PublicCompanyProfileResponse) => response.data
        })
    })
});