import React, { useEffect } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dynamic from "next/dynamic";
import TextInput from "@components/UI/Inputs/TextInput/TextInput";
import { uploadFile } from "@app/store/offersApi";
import { AppDispatch } from "@app/store/store";
import { useGetCompanyProfileQuery, useUpdateCompanyProfileMutation } from "@entities/profile";
import { CustomLoader } from "@shared/ui/CustomLoader";
import { VerifiedIcon } from "@shared/ui/Icon/ui/Profile/VerifiedIcon";
import { WaitingIcon } from "@shared/ui/Icon/ui/Profile/WaitingIcon";
import classes from "./CompanyForm.module.scss";

const AvatarProfile = dynamic(
    () => import("./AvatarProfile/AvatarProfile").then((mod) => mod.AvatarProfile),
    { ssr: false }
);

export const CompanyForm = () => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { dirtyFields, errors }
    } = useForm({
        mode: "all"
    });

    const { data: companyData, isLoading } = useGetCompanyProfileQuery();
    const [updateCompanyProfile] = useUpdateCompanyProfileMutation();

    const defaultLogo = companyData?.logoUrl || "";
    const logoUrl = watch("logoUrl", defaultLogo);

    const handleLogoChange = (file: File | null) => {
        setValue("logoUrl", file ? URL.createObjectURL(file) : "");
    };

    const handleLogoDelete = () => {
        const isBlob = logoUrl.startsWith("blob:");
        const newLogoUrl = isBlob ? defaultLogo : "";
        setValue("logoUrl", newLogoUrl);
    };

    const displayError = (name: string) => {
        const errorMessage = errors?.[name]?.message?.toString();
        return errorMessage ? (
            <span className={classes["company__input--error"]}>{errorMessage}</span>
        ) : null;
    };

    const onSubmit = async (formData: FieldValues) => {
        let updatedLogoUrl = logoUrl;

        if (updatedLogoUrl.startsWith("blob:")) {
            try {
                const fileFormData = new FormData();
                const response = await fetch(updatedLogoUrl);
                const blob = await response.blob();
                fileFormData.append("file", new File([blob], "avatar.jpg", { type: "image/jpeg" }));

                const fileData = await dispatch(uploadFile(fileFormData)).unwrap();
                if (fileData.error) throw new Error(fileData.error);

                updatedLogoUrl = fileData.data.data.big;
            } catch {
                toast.error("Ошибка при загрузке изображения.");
                return;
            }
        }

        const updatedFields = {
            ...Object.fromEntries(Object.keys(dirtyFields).map((key) => [key, formData[key]])),
            ...(updatedLogoUrl !== companyData?.logoUrl ? { logoUrl: updatedLogoUrl } : {})
        };

        if (Object.keys(updatedFields).length === 0) {
            return;
        }

        try {
            await updateCompanyProfile(updatedFields).unwrap();
            toast.success("Информация о компании успешно изменена.");
            setValue("logoUrl", updatedLogoUrl);
        } catch {
            toast.error("Ошибка при обновлении информации о компании.");
        }
    };

    useEffect(() => {
        if (companyData) {
            reset(companyData);
        }
    }, [companyData, reset]);

    return (
        <form className={classes.company} onSubmit={handleSubmit(onSubmit)}>
            <p className={classes.company__title}>Информация о компании</p>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <>
                    <div className={classes.company__wrapper}>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>Название компании</label>
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: "Название компании не может быть пустым"
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        maxLength={255}
                                        placeholder="Введите название компании"
                                    />
                                )}
                            />
                            {displayError("name")}
                            <div className={classes.company__status}>
                                {companyData?.isVerified ? (
                                    <>
                                        <VerifiedIcon />
                                        Проверенный аккаунт
                                    </>
                                ) : (
                                    <>
                                        <WaitingIcon />
                                        Аккаунт на модерации
                                    </>
                                )}
                            </div>
                        </div>
                        <AvatarProfile
                            initialSrc={logoUrl}
                            onChange={handleLogoChange}
                            onDelete={handleLogoDelete}
                        />
                    </div>
                    <p className={classes.company__title}>Юридическая информация</p>
                    <div className={classes.company__wrapper}>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>ИНН</label>
                            <Controller
                                control={control}
                                name="inn"
                                rules={{
                                    required: "ИНН не может быть пустым",
                                    pattern: {
                                        value: /^(\d{10}|\d{12})$/,
                                        message: "ИНН должно содержать 10 или 12 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите ИНН"
                                    />
                                )}
                            />
                            {displayError("inn")}
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>КПП</label>
                            <Controller
                                control={control}
                                name="kpp"
                                rules={{
                                    required: "КПП не может быть пустым",
                                    pattern: {
                                        value: /^\d{9}$/,
                                        message: "КПП должен содержать ровно 9 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите КПП"
                                    />
                                )}
                            />
                            {displayError("kpp")}
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>Юридический адрес</label>
                            <Controller
                                control={control}
                                name="legalAddress"
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите юридический адрес"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>ОГРН</label>
                            <Controller
                                control={control}
                                name="ogrn"
                                rules={{
                                    required: "ОГРН не может быть пустым",
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "ОГРН должно содержать только цифры"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите ОГРН"
                                    />
                                )}
                            />
                            {displayError("ogrn")}
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>ОКПО</label>
                            <Controller
                                control={control}
                                name="okpo"
                                rules={{
                                    pattern: {
                                        value: /^(\d{8}|\d{10}|\d{14})$/,
                                        message: "ОКПО должно содержать 8, 10 или 14 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите ОКПО"
                                    />
                                )}
                            />
                            {displayError("okpo")}
                        </div>
                    </div>
                    <div className={classes.company__divider} />
                    <div className={classes.company__wrapper}>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>Банк</label>
                            <Controller
                                control={control}
                                name="bank"
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        maxLength={255}
                                        placeholder="Введите банк"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>Расчетный счет</label>
                            <Controller
                                control={control}
                                name="checkingAccount"
                                rules={{
                                    required: "Введите расчетный счет",
                                    pattern: {
                                        value: /^\d{20}$/,
                                        message: "Расчетный счет состоит ровно из 20 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите расчетный счет"
                                    />
                                )}
                            />
                            {displayError("checkingAccount")}
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>БИК</label>
                            <Controller
                                control={control}
                                name="bic"
                                rules={{
                                    pattern: {
                                        value: /^(\d{8}|\d{11})$/,
                                        message: "БИК должно содержать 8 или 11 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите БИК"
                                    />
                                )}
                            />
                            {displayError("bic")}
                        </div>
                        <div className={classes.company__input}>
                            <label className={classes.company__label}>
                                Корреспондентский счет Банка
                            </label>
                            <Controller
                                control={control}
                                name="correspondentAccount"
                                rules={{
                                    required: "Введите корреспондентский счет",
                                    pattern: {
                                        value: /^\d{20}$/,
                                        message: "Корреспондентский счет состоит ровно из 20 цифр"
                                    }
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder="Введите корреспондентский счет Банка"
                                    />
                                )}
                            />
                            {displayError("correspondentAccount")}
                        </div>
                    </div>
                    <div className={classes.company__actions}>
                        <button type="submit" className={classes.company__save}>
                            Сохранить
                        </button>
                        <button
                            type="button"
                            className={classes.company__cancel}
                            onClick={() => reset()}
                        >
                            Отмена
                        </button>
                    </div>
                </>
            )}
        </form>
    );
};