import { HYDRATE } from "next-redux-wrapper";
import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginPayload } from "../../interfaces/auth.interface";
import { User } from "../../interfaces/user.interface";
import { hideModals, hideRegistrationForm } from "./layoutSlice";
import { AppState } from "./store";
const hydrate = createAction<AppState>(HYDRATE);
const API = process.env.NEXT_PUBLIC_API_URL;
import { toast } from "react-toastify";
import Router from "next/router";
import { uuidv7 } from "uuidv7";
import { mergeFavorites, offersApi } from "@app/store/offersApi";
import { profileApi } from "@entities/profile";
import { isSuccessHTTPCode } from "../../utils/utils";
export interface AuthState {
    isPersonal?: boolean | null;
    currentUser: User | null;
    accessToken?: string;
    authState: boolean;
    isLoggingIn: boolean;
    isRegistering: boolean;
    errorMessage: string | any | null;
    routeToOpen?: string;
    emailMemory?: string;
    phoneMemory?: string;
}

const initialState: AuthState = {
    currentUser: null,
    authState: false,
    isLoggingIn: false,
    isRegistering: false,
    errorMessage: null,
    isPersonal: null
};

export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const UUID_TOKEN_KEY = "uuid";

export const getUUID = () => {
    if (typeof window !== "undefined") {
        const uuid = localStorage.getItem(UUID_TOKEN_KEY);
        if (!uuid) {
            const newUuid = uuidv7();
            localStorage.setItem(UUID_TOKEN_KEY, newUuid);
            return newUuid;
        }
        return uuid;
    } else {
        return "";
    }
};

export const getAccessToken = () => {
    if (typeof window !== "undefined") {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return "";
        }
        return token;
    } else {
        return "";
    }
};
export const getRefreshToken = () => {
    if (typeof window !== "undefined") {
        const token = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!token) {
            return "";
        }
        return token;
    } else {
        return "";
    }
};

export const setAccessToken = (token: string) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(TOKEN_KEY, token);
        return true;
    }
    return false;
};

export const setRefreshToken = (token: string) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
        return true;
    }
    return false;
};

export const removeAccessToken = () => {
    if (typeof window !== "undefined") {
        localStorage.removeItem(TOKEN_KEY);
        return true;
    }
    return false;
};

export const removeRefreshToken = () => {
    if (typeof window !== "undefined") {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        return true;
    }
    return false;
};

export const getUserData = () => {
    if (typeof window === "undefined") return null;

    const user = localStorage.getItem("user");
    if (!user) return null;

    try {
        return JSON.parse(user);
    } catch {
        return null;
    }
};

export const setUserData = (user: User) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("user", JSON.stringify(user));
        return true;
    }
    return false;
};

export const clearAuthData = () => {
    removeAccessToken();
    removeRefreshToken();

    if (typeof window !== "undefined") {
        localStorage.removeItem("user");
        return true;
    }
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, action) {
            state.authState = action.payload;
        },
        logoutUser(state) {
            state.authState = false;
            state.currentUser = null;
            state.accessToken = undefined;
            clearAuthData();
        },
        setUser(state, action: PayloadAction<User>) {
            state.currentUser = action.payload;
        },
        setRouteToOpen(state, action: PayloadAction<string>) {
            state.routeToOpen = action.payload;
        },
        clearErrors(state) {
            state.errorMessage = null;
        },
        setReduxAccessToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload;
        },
        setEmailMemory(state, action: PayloadAction<string>) {
            state.emailMemory = action.payload;
        },
        setPhoneMemory(state, action: PayloadAction<string>) {
            debugger;
            state.phoneMemory = action.payload;
        },
        setIsPersonal(state, action: PayloadAction<boolean | null>) {
            state.isPersonal = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(hydrate, (state, action) => {
            if (action.payload.auth) {
                state.currentUser = action.payload.auth.currentUser ?? state.currentUser;
                state.authState = action.payload.auth.authState ?? state.authState;
            }
        });

        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.isLoggingIn = false;
            state.authState = true;
            state.currentUser = action.payload?.user || null;
        });

        builder.addCase(loginUser.pending, (state) => {
            state.errorMessage = null;
            state.isLoggingIn = true;
        });

        builder.addCase(loginUser.rejected, (state, action) => {
            if (action.payload) {
                state.errorMessage = action.payload.message;
            }
            state.isLoggingIn = false;
        });

        builder.addCase(registerUser.fulfilled, (state, action) => {
            state.isRegistering = false;
            state.authState = true;
            state.currentUser = action.payload?.user || null;
        });

        builder.addCase(registerUser.pending, (state) => {
            state.errorMessage = null;
            state.isRegistering = true;
        });

        builder.addCase(registerUser.rejected, (state, action) => {
            if (action.payload) {
                state.errorMessage = action.payload.message;
            }
            state.isRegistering = false;
        });
    }
});

export const toastAction = () => {
    if (typeof window !== "undefined") {
        toast.error(`Ошибка на сервере: текст ошибки, код ошибки: 500`);
    }

    return {
        type: "toastAction",
        payload: {}
    };
};

interface LoginResponse {
    message?: string;
    data: {
        accessToken?: string;
        refreshToken: string;
    };
    user?: User;
}

export const sendSmsCode = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/sendSmsCode",
    async (payload: any, thunkAPI) => {
        const TOKEN = getAccessToken();
        try {
            const response = await fetch(`${API}/v1/verification/phone/change`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-token": TOKEN
                },
                body: JSON.stringify(payload)
            });

            let data = await response.json();

            if (isSuccessHTTPCode(response.status)) {
                return data;
            } else {
                toast.error(`Ошибка на сервере: ${data.message}, код ошибки: ${response.status}`, {
                    autoClose: 5000
                });
                throw new Error(data.message);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const confirmEmailCode = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/confirmEmailCode",
    async (payload: any, thunkAPI) => {
        const TOKEN = getAccessToken();
        try {
            const response = await fetch(`${API}/v1/verification/email/second_step`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-token": TOKEN
                },
                body: JSON.stringify(payload)
            });

            let data = await response.json();

            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const sendEmailLink = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/sendEmailLink",
    async (payload: any, thunkAPI) => {
        const TOKEN = getAccessToken();
        try {
            const response = await fetch(`${API}/v1/verification/email/first_step`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-token": TOKEN
                },
                body: JSON.stringify(payload)
            });

            let data = await response.json();

            if (response.status === 200) {
                return data;
            } else {
                throw new Error(data.message);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const sendSmsVerificationdCode = createAsyncThunk<
    any,
    any,
    { rejectValue: { message: string } }
>("auth/sendSmsVerificationdCode", async (payload: string, thunkAPI) => {
    const TOKEN = getAccessToken();
    try {
        const response = await fetch(`${API}/v1/verification/phone/first_step`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-token": TOKEN
            },
            body: JSON.stringify({
                phone: payload
            })
        });

        let data = await response.json();

        if (isSuccessHTTPCode(response.status)) {
            thunkAPI.dispatch(setPhoneMemory(payload));
            return data;
        } else {
            toast.error(`Ошибка на сервере: ${data.message}, код ошибки: ${response.status}`, {
                autoClose: 5000
            });
            throw new Error(data.message);
        }
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ message: error.message });
    }
});

export const confirmSmsCode = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/verifyCode",
    async (payload: any, thunkAPI) => {
        const TOKEN = getAccessToken();

        try {
            const response = await fetch(`${API}/v1/verification/phone/second_step`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-token": TOKEN
                },
                body: JSON.stringify(payload)
            });

            let data = await response.json();

            if (response.status >= 200 && response.status < 300) {
                thunkAPI.dispatch(hideModals());
            }

            return {
                status: response.status,
                data: data
            };
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const loginUser = createAsyncThunk<LoginResponse, any, { rejectValue: { message: string } }>(
    "auth/login",
    async (payload: LoginPayload, thunkAPI) => {
        try {
            const response = await fetch(`${API}/v1/users/authentication`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-DEVICE-UUID": getUUID()
                },
                body: JSON.stringify(payload)
            });

            let data: LoginResponse = await response.json();

            if (response.status === 200) {
                const token = data?.data?.accessToken;
                const refreshToken = data?.data?.refreshToken;

                if (!token) {
                    throw new Error("No token come from server");
                }
                setAccessToken(token);
                setRefreshToken(refreshToken);

                thunkAPI.dispatch(hideModals());

                const profileQuery = profileApi.endpoints.getProfile.initiate({
                    accessToken: token
                });

                const res: any = await thunkAPI.dispatch(profileQuery);
                const userData = res.data;

                localStorage.setItem("user", JSON.stringify(userData));

                thunkAPI.dispatch(setReduxAccessToken(token));
                await thunkAPI.dispatch(mergeFavorites());
                thunkAPI.dispatch(offersApi.util.invalidateTags(["Favorites"]));
                return {
                    ...data,
                    user: userData
                };
            } else {
                clearAuthData();
                if (response.status === 400) {
                    return thunkAPI.rejectWithValue({
                        message: data.message || "Неверный логин или пароль"
                    });
                } else {
                    return thunkAPI.rejectWithValue({
                        message: data.message || "Сервер не отвечает"
                    });
                }
            }
        } catch (e: any) {
            return thunkAPI.rejectWithValue({
                message: e.response.data || "Something went wrong"
            });
        }
    }
);

export const refreshToken = createAsyncThunk<
    LoginResponse,
    any,
    { rejectValue: { message: string } }
>("auth/refreshToken", async (payload: any, thunkAPI) => {
    const TOKEN = getRefreshToken();

    try {
        const response = await fetch(`${API}/v1/auth/get_access_token_by_refresh_token`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-Refresh-Token": TOKEN,
                "X-DEVICE-UUID": getUUID()
            }
        });

        let data = await response.json();

        if (response.status === 401) {
            clearAuthData();
            thunkAPI.dispatch(logoutUser());
        }
        if (response.status === 200) {
            const token = data?.data?.accessToken;
            const refreshToken = data?.data?.refreshToken;

            if (!token) {
                throw new Error("No token come from server");
            }

            setAccessToken(token);
            setRefreshToken(refreshToken);

            return data;
        } else {
            throw new Error(data.message);
        }
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ message: error.message });
    }
});

export const changePassword = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/changePassword",
    async (payload: any, thunkAPI) => {
        try {
            const response = await fetch(`${API}/v1/users/me/password`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    code: payload.code,
                    newPassword: payload.newPassword,
                    repeatedPassword: payload.repeatedPassword
                })
            });

            const data = await response.json();

            if (!response.ok) {
                return thunkAPI.rejectWithValue({
                    message: data?.data?.errors || "Неизвестная ошибка"
                });
            }

            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const sendRestorePasswordLink = createAsyncThunk<
    any,
    any,
    { rejectValue: { message: string } }
>("auth/sendRestorePasswordLink", async (email: any, thunkAPI) => {
    try {
        const response = await fetch(`${API}/v1/users/me/password/recovery/codes`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email
            })
        });

        let data = await response.json();

        if (response.status === 200) {
            return data;
        } else {
            throw new Error(data.message);
        }
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ message: error.message });
    }
});

export const isRgistered = createAsyncThunk<any, any, { rejectValue: { message: string } }>(
    "auth/isRgistered",
    async (email: string, thunkAPI) => {
        try {
            const response = await fetch(`${API}/v1/users/is_registered`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email
                })
            });

            let data = await response.json();

            if (response.status === 200) {
                return true;
            } else if (response.status === 404) {
                return false;
            } else {
                throw new Error(data.message);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const fastLogin = createAsyncThunk<LoginResponse, any, { rejectValue: { message: string } }>(
    "auth/fastLogin",
    async (payload: any, thunkAPI) => {
        try {
            const body = payload?.password
                ? {
                      phone: payload.phone,
                      password: payload.password
                  }
                : {
                      phone: payload.phone
                  };
            const response = await fetch(`${API}/v1/users/authentication/fast`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-DEVICE-UUID": getUUID()
                },
                body: JSON.stringify(body)
            });

            let data = await response.json();

            if (response.status === 200) {
                if (payload?.password) {
                    const token = data?.data?.accessToken;
                    const refreshToken = data?.data?.refreshToken;

                    if (!token) {
                        throw new Error("No token come from server");
                    }

                    setAccessToken(token);
                    setRefreshToken(refreshToken);

                    const profileQuery = profileApi.endpoints.getProfile.initiate({
                        accessToken: token
                    });

                    const res = await thunkAPI.dispatch(profileQuery);

                    localStorage.setItem("user", JSON.stringify(res.data));

                    return res.data;
                } else {
                    return {
                        data: data.data,
                        status: response.status
                    };
                }

                return data;
            } else {
                throw new Error(data.message);
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ message: error.message });
        }
    }
);

export const registerUser = createAsyncThunk<LoginResponse, any, { rejectValue: { message: any } }>(
    "auth/register",
    async (payload: LoginPayload, thunkAPI) => {
        try {
            const response = await fetch(`${API}/v1/users/registration`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "X-DEVICE-UUID": getUUID() },
                body: JSON.stringify(payload)
            });

            let data: LoginResponse = await response.json();

            if (response.status === 200) {
                const token = data?.data?.accessToken;
                const refreshToken = data?.data?.refreshToken;
                if (!token) {
                    throw new Error("No token come from server");
                }
                setAccessToken(token);
                setRefreshToken(refreshToken);
                thunkAPI.dispatch(hideRegistrationForm());
                const profileQuery = profileApi.endpoints.getProfile.initiate({
                    accessToken: token
                });

                const res = await thunkAPI.dispatch(profileQuery);

                localStorage.setItem("user", JSON.stringify(res.data));
                return {
                    ...data,
                    user: res.data
                };
            } else {
                removeAccessToken();
                removeRefreshToken();

                const message =
                    typeof data.data === "object" && !Array.isArray(data?.data)
                        ? data.data
                        : data?.message;
                return thunkAPI.rejectWithValue({
                    message: message || "Something went wrong"
                });
            }
        } catch (e: any) {
            return thunkAPI.rejectWithValue({
                message: e.response.data || "Something went wrong"
            });
        }
    }
);

export const authByOAuthToken = createAsyncThunk<
    any,
    { platform: string; accessToken: string },
    { rejectValue: { message: string } }
>("auth/authByOAuthToken", async ({ platform, accessToken }, thunkAPI) => {
    try {
        const url = `${API}/v1/oauth/${platform}/tokens?accessToken=${accessToken}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        let data = await response.json();

        if (response.status === 200) {
            const token = data?.data?.accessToken;
            const refreshToken = data?.data?.refreshToken;
            if (!token) {
                throw new Error("No token come from server");
            }
            setAccessToken(token);
            setRefreshToken(refreshToken);
            thunkAPI.dispatch(hideRegistrationForm());
            const profileQuery = profileApi.endpoints.getProfile.initiate({
                accessToken: token
            });
            const res = await thunkAPI.dispatch(profileQuery);

            localStorage.setItem("user", JSON.stringify(res.data));
            await Router.push("/");
            return {
                ...data,
                user: res.data
            };
        } else {
            const message =
                typeof data.data === "object" && !Array.isArray(data?.data)
                    ? data.data
                    : data?.message;
            return thunkAPI.rejectWithValue({
                message: message || "Something went wrong"
            });
        }
    } catch (e: any) {
        return thunkAPI.rejectWithValue({
            message: e.response.data || "Something went wrong"
        });
    }
});

export const authByOAuthTelegram = createAsyncThunk(
    "auth/authByOAuthTelegram",
    async (authData: any, thunkAPI) => {
        try {
            const queryString = new URLSearchParams(authData as Record<string, string>).toString();
            const url = `${API}/v1/oauth/telegram/tokens?${queryString}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const data = await response.json();

            if (response.status === 200) {
                const token = data?.data?.accessToken;
                const refreshToken = data?.data?.refreshToken;
                if (!token) {
                    throw new Error("No token come from server");
                }
                setAccessToken(token);
                setRefreshToken(refreshToken);
                thunkAPI.dispatch(hideRegistrationForm());
                const profileQuery = profileApi.endpoints.getProfile.initiate({
                    accessToken: token
                });
                const res = await thunkAPI.dispatch(profileQuery);
                localStorage.setItem("user", JSON.stringify(res.data));
                await Router.push("/");
                return {
                    ...data,
                    user: res.data
                };
            } else {
                const message = data.message || "Something went wrong";
                return thunkAPI.rejectWithValue({ message });
            }
        } catch (error) {
            const errorMessage =
                error instanceof Error ? error.message : "An unexpected error occurred";
            console.error("Error during Telegram OAuth:", errorMessage);
            return thunkAPI.rejectWithValue({ message: errorMessage });
        }
    }
);

export const {
    setAuthState,
    logoutUser,
    setUser,
    setRouteToOpen,
    clearErrors,
    setReduxAccessToken,
    setEmailMemory,
    setPhoneMemory,
    setIsPersonal
} = authSlice.actions;

export const selectIsCompany = (state: AppState) => Boolean(state.auth.currentUser?.isCompany);
export const selectIsAuthenticated = (state: AppState) => Boolean(state.auth.currentUser);
export const currentUserState = (state: AppState) => state.auth.currentUser;

export default authSlice.reducer;