import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const WaitingIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
    >
        <path
            fill="#E8A14D"
            d="M8 16c4.411 0 8-3.589 8-8s-3.589-8-8-8-8 3.589-8 8 3.589 8 8 8ZM8 2.064A5.943 5.943 0 0 1 13.937 8 5.943 5.943 0 0 1 8 13.936 5.943 5.943 0 0 1 2.063 8 5.943 5.943 0 0 1 8 2.064Z"
        />
        <path
            fill="#E8A14D"
            d="M8 9.122H11.874a.929.929 0 0 0 0-1.857H8.928V4.382a.929.929 0 0 0-1.857 0v3.812c0 .512.416.928.929.928Z"
        />
    </svg>
);