import { toast } from "react-toastify";
import { createListenerMiddleware, ListenerEffectAPI, TypedStartListening } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { authByOAuthTelegram, authByOAuthToken, fastLogin, loginUser } from "@app/store/authSlice";
import { showCompareForm } from "@app/store/layoutSlice";
import { AppDispatch, AppState } from "@app/store/store";
import { compareApi, loadCompareOffersFromLocalStorage } from "@entities/compare";
import { saveCompareOffersToLocalStorage } from "@entities/compare/lib";
import { ERROR_SYNC_COMPARE } from "./constants";

export const compareListenerMiddleware = createListenerMiddleware();

export type CompareStartListening = TypedStartListening<AppState, AppDispatch>;

const startListening = compareListenerMiddleware.startListening as CompareStartListening;

startListening({
    actionCreator: loginUser.fulfilled,
    effect: async (_, api) => {
        await handleCompareSync(api);
    }
});

startListening({
    actionCreator: fastLogin.fulfilled,
    effect: async (_, api) => {
        await handleCompareSync(api);
    }
});

startListening({
    actionCreator: authByOAuthToken.fulfilled,
    effect: async (_, api) => {
        await handleCompareSync(api);
    }
});

startListening({
    actionCreator: authByOAuthTelegram.fulfilled,
    effect: async (_, api) => {
        await handleCompareSync(api);
    }
});

async function handleCompareSync(api: ListenerEffectAPI<AppState, AppDispatch>) {
    const offers = loadCompareOffersFromLocalStorage();
    if (!offers.length) return;

    try {
        await api
            .dispatch(
                compareApi.endpoints.mergeOffersComparison.initiate({
                    offerUuids: offers,
                    isRewrite: false
                })
            )
            .unwrap();

        saveCompareOffersToLocalStorage([]);
    } catch (e) {
        const error = e as FetchBaseQueryError;

        if (error?.status === 409) {
            api.dispatch(showCompareForm());
        } else {
            toast.error(ERROR_SYNC_COMPARE);
        }
    }
}