import { toast } from "react-toastify";
import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import {
    CompareOffersByIdsResponse,
    CompareOffersByIdsResponseData,
    CompareOffersResponse,
    CompareOffersResponseData,
    MergeCompareOffersArgs
} from "@entities/compare/api/types";
import { baseQueryWithReauth } from "@shared/api/baseQueryWithReauth";
import { configApi } from "@shared/lib/configApi";
import { convertToSafeUrl } from "@shared/lib/utils/convertToSafeUrl";
import { ERROR_MESSAGES } from "./constants";

export const compareApi = createApi({
    reducerPath: "compareApi",
    tagTypes: ["COMPARE_OFFERS_TAG", "COMPARE_LOCALE_OFFERS_TAG"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getCompareOffers: builder.query<CompareOffersResponseData, void>({
            query: () => ({
                url: `${configApi.API_URL}/v1/users/me/comparison/offers`
            }),
            transformResponse: (response: CompareOffersResponse) => {
                const offers = response.data.offers.map((offer) => ({
                    ...offer,
                    imagesList: offer.imagesList.map((image) => ({
                        big: convertToSafeUrl(image.big) ?? "",
                        small: convertToSafeUrl(image.small) ?? ""
                    }))
                }));
                return {
                    ...response.data,
                    offers
                };
            },
            providesTags: ["COMPARE_OFFERS_TAG"]
        }),
        getCompareOffersByIds: builder.query<CompareOffersByIdsResponseData, string[]>({
            query: (ids) => {
                const params = new URLSearchParams();
                ids.forEach((id) => params.append("ids[]", id));
                return {
                    url: `${configApi.API_URL}/v1/comparison/offers?${params.toString()}`
                };
            },
            transformResponse: (response: CompareOffersByIdsResponse) => {
                return {
                    offers: response.data.map((offer) => ({
                        ...offer,
                        imagesList:
                            offer.imagesList?.map((image) => ({
                                big: convertToSafeUrl(image.big) ?? "",
                                small: convertToSafeUrl(image.small) ?? ""
                            })) ?? []
                    }))
                };
            },
            providesTags: ["COMPARE_LOCALE_OFFERS_TAG"]
        }),
        getCompareOffersById: builder.query<CompareOffersResponseData, string>({
            query: (id) => ({
                url: `${configApi.API_URL}/v1/comparison/${id}/offers`
            }),
            transformResponse: (response: CompareOffersResponse) => {
                const offers = response.data.offers.map((offer) => ({
                    ...offer,
                    imagesList: offer.imagesList.map((image) => ({
                        big: convertToSafeUrl(image.big) ?? "",
                        small: convertToSafeUrl(image.small) ?? ""
                    }))
                }));
                return {
                    ...response.data,
                    offers
                };
            }
        }),
        addOfferToComparison: builder.mutation<void, { offerUuid: string }>({
            query: ({ offerUuid }) => ({
                url: `${configApi.API_URL}/v1/users/me/comparison/offers`,
                method: "POST",
                body: { offerUuid },
                extraOptions: {
                    showForm: true,
                    intension: {
                        action: "addOfferToComparison",
                        args: { offerUuid }
                    }
                }
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error) {
                    const err = error as { error: FetchBaseQueryError };
                    if (err.error?.status === 409) {
                        toast.error(ERROR_MESSAGES.MAX_OFFERS_REACHED);
                    }
                }
            },
            invalidatesTags: ["COMPARE_OFFERS_TAG"]
        }),
        updateCompareOffers: builder.mutation<CompareOffersResponseData, string>({
            query: (id) => ({
                url: `${configApi.API_URL}/v1/comparison/${id}/offers/price/conversion`,
                method: "POST",
                extraOptions: {
                    showForm: true,
                    intension: {
                        action: "updateCompareOffers"
                    }
                }
            }),
            invalidatesTags: ["COMPARE_OFFERS_TAG"]
        }),
        removeOfferFromComparison: builder.mutation<void, { offerId: string }>({
            query: ({ offerId }) => ({
                url: `${configApi.API_URL}/v1/users/me/comparison/offers/${offerId}`,
                method: "DELETE"
            }),
            invalidatesTags: ["COMPARE_OFFERS_TAG"]
        }),
        removeAllOffersComparison: builder.mutation<void, void>({
            query: () => ({
                url: `${configApi.API_URL}/v1/users/me/comparison/offers`,
                method: "DELETE"
            }),
            invalidatesTags: ["COMPARE_OFFERS_TAG"]
        }),
        mergeOffersComparison: builder.mutation<void, MergeCompareOffersArgs>({
            query: ({ offerUuids, isRewrite }) => ({
                url: `${configApi.API_URL}/v1/users/me/comparison/offers/bulk`,
                method: "POST",
                body: { offerUuids, isRewrite }
            }),
            invalidatesTags: ["COMPARE_OFFERS_TAG"]
        })
    })
});

export const {
    useGetCompareOffersQuery,
    useGetCompareOffersByIdsQuery,
    useAddOfferToComparisonMutation,
    useUpdateCompareOffersMutation,
    useRemoveOfferFromComparisonMutation,
    useRemoveAllOffersComparisonMutation,
    useMergeOffersComparisonMutation
} = compareApi;