import { createAsyncThunk } from "@reduxjs/toolkit";
import { authSlice } from "@app/store/authSlice";
import { baseQueryWithReauth } from "@shared/api/baseQueryWithReauth";
import { getDeviceUUID } from "@shared/api/getDeviceUuid";
import { configApi } from "@shared/lib/configApi";

const deviceUUID = getDeviceUUID();

export const logoutUserSessions = createAsyncThunk<
    void,
    void,
    { rejectValue: { message: string } }
>("auth/logoutUserSessions", async (_, thunkAPI) => {
    const URL = `${configApi.API_URL}/v1/users/me/tokens`;

    const result = await baseQueryWithReauth(
        {
            url: URL,
            method: "DELETE",
            headers: {
                ...(deviceUUID && { "X-Device-UUID": deviceUUID })
            }
        },
        {
            ...thunkAPI,
            endpoint: URL,
            type: "mutation"
        },
        {}
    );

    if (result.error) {
        const errorData = result.error.data as { message?: string } | undefined;
        return thunkAPI.rejectWithValue({
            message: errorData?.message || "Ошибка при выходе"
        });
    }

    thunkAPI.dispatch(authSlice.actions.logoutUser());
});

export const checkRecoveryCode = createAsyncThunk<boolean, string>(
    "auth/checkRecoveryCode",
    async (token, thunkAPI) => {
        const URL = `${configApi.API_URL}/v1/users/me/password/recovery/codes/${token}`;

        const result = await baseQueryWithReauth(
            {
                url: URL,
                method: "GET"
            },
            {
                ...thunkAPI,
                endpoint: URL,
                type: "query"
            },
            {}
        );

        if (result.error) {
            const errorData = result.error.data as { message?: string } | undefined;
            return thunkAPI.rejectWithValue({
                message: errorData?.message || "Ошибка проверки кода"
            });
        }

        return true;
    }
);