import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { offersApi } from "@app/store/offersApi";
import { getAccessToken } from "@shared/api/getAccessToken";
import Car from "@shared/lib/interfaces/car.interface";
import localFavoritesSlice from "./local-favorites-api";
import { FavoriteArg, FavoritesState } from "./types";

const initialState: FavoritesState = {
    favorites: []
};

const favoritesSlice = createSlice({
    name: "favorites",
    initialState,
    reducers: {}
});

export const addToFavorits = createAsyncThunk<void, FavoriteArg>(
    "favorites/addToFavorits",
    async (data, { rejectWithValue, dispatch }) => {
        const TOKEN = getAccessToken();
        if (TOKEN) {
            await dispatch(offersApi.endpoints.addToFavorites.initiate(data));
        } else {
            dispatch(localFavoritesSlice.actions.addFavorite(data.id));
        }
    }
);

export const removeFromFavorits = createAsyncThunk<void, FavoriteArg>(
    "favorites/removeFromFavorits",
    async (data, { dispatch }) => {
        const TOKEN = getAccessToken();
        if (TOKEN) {
            await dispatch(offersApi.endpoints.removeFromFavorites.initiate(data));
        } else {
            dispatch(localFavoritesSlice.actions.removeFavorite(data.id));
        }
    }
);

export const getFavoritesList = createAsyncThunk(
    "favorites/getFavoritesList",
    async (data, { dispatch, getState, rejectWithValue }): Promise<Car[]> => {
        try {
            const TOKEN = getAccessToken();
            if (TOKEN) {
                const payload = await dispatch(
                    offersApi.endpoints.getFavoritesOffers.initiate(data)
                );
                return payload.data || [];
            } else {
                const state = getState() as { localFavorites: FavoritesState };
                const ids = state.localFavorites.favorites;
                if (ids.length === 0) {
                    return [];
                }
                const payload = await dispatch(offersApi.endpoints.getOffersByIds.initiate(ids));
                return payload.data || [];
            }
        } catch (error) {
            rejectWithValue("Failed to fetch favorites list");
            return [];
        }
    }
);

export default favoritesSlice;