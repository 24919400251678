import {
    BaseQueryApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { refreshToken } from "./authSlice";
import { addIntension, Intension } from "./intensionsSlice";
import { showEnterForm } from "./layoutSlice";

export const baseQuery = fetchBaseQuery({
    timeout: 60000
});

export declare type BaseQueryFnWithForm<
    Args = any,
    Result = unknown,
    Error = unknown,
    DefinitionExtraOptions = {},
    Meta = {}
> = (
    args: Args,
    api: BaseQueryApi,
    extraOptions: DefinitionExtraOptions,
    showForm?: boolean,
    intension?: Intension
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

export const baseQueryWithReauth: BaseQueryFnWithForm<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions, showForm = true, intension?: Intension) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (intension) {
            api.dispatch(addIntension(intension));
        }
        const refreshData = await api.dispatch(refreshToken({}));

        if (refreshData.type === "auth/refreshToken/rejected" && showForm) {
            api.dispatch(showEnterForm());
        }
    }
    return result;
};