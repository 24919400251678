import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Intension {
    action: string;
    args: any;
}

export interface IntensionsState {
    intensions: Intension[];
}

const initialState: IntensionsState = {
    intensions: []
};

export const intensionsSlice = createSlice({
    name: "intensions",
    initialState,
    reducers: {
        addIntension: (state, action: PayloadAction<Intension>) => {
            state.intensions.push(action.payload);
        },
        clearIntensions: (state) => {
            state.intensions = [];
        }
    }
});

export const { addIntension, clearIntensions } = intensionsSlice.actions;

export default intensionsSlice;