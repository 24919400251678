import { HYDRATE } from "next-redux-wrapper";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: "/blog-api/",
    prepareHeaders: (headers) => {
        return headers;
    }
});

export const blogApi = createApi({
    reducerPath: "blogApi",
    baseQuery: baseQuery,
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath];
        }
    },
    endpoints: (builder) => ({
        getPosts: builder.query({
            query: ({ count, exclude = 0, page = 1 }) => ({
                url: `/posts?_fields=id,title,slug,date,views,status,x_featured_media_large&per_page=${count}&_embed&exclude=${exclude}&page=${page}`,
                method: "GET"
            }),
            transformResponse(apiResponse, meta) {
                return {
                    data: apiResponse as any[],
                    pagesCount: Number(meta?.response?.headers.get("X-WP-TotalPages"))
                };
            }
        }),
        getPostItem: builder.query({
            query: (id) => ({
                url: `/posts/?slug=${id}`,
                method: "GET"
            })
        })
    })
});

export const {
    useGetPostsQuery,
    useGetPostItemQuery,
    util: { getRunningQueriesThunk }
} = blogApi;

export const { getPosts, getPostItem } = blogApi.endpoints;