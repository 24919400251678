import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FavoritesState } from "./types";

const isBrowser = typeof document !== "undefined";

const initialState: FavoritesState = {
    favorites: isBrowser ? JSON.parse(localStorage.getItem("favorites") || "[]") : []
};

const localFavoritesSlice = createSlice({
    name: "localFavorites",
    initialState,
    reducers: {
        addFavorite: (state, action: PayloadAction<string>) => {
            state.favorites = [...state.favorites, action.payload].filter(
                (value, index, self) => self.indexOf(value) === index
            );
            if (isBrowser) {
                localStorage.setItem("favorites", JSON.stringify(state.favorites));
            }
        },
        removeFavorite: (state, action: PayloadAction<string>) => {
            state.favorites = state.favorites.filter((id) => id !== action.payload);

            if (isBrowser) {
                localStorage.setItem("favorites", JSON.stringify(state.favorites));
            }
        },
        clearFavorites: (state) => {
            state.favorites = [];
            if (isBrowser) {
                localStorage.setItem("favorites", JSON.stringify(state.favorites));
            }
        }
    }
});

export const { addFavorite, removeFavorite, clearFavorites } = localFavoritesSlice.actions;

export default localFavoritesSlice;