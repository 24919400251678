import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const VerifiedIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.77914 0.644839C7.93184 0.45172 8.22479 0.45172 8.37749 0.64484L9.70745 2.32678C9.80781 2.45371 9.97735 2.50349 10.1304 2.45097L12.1586 1.75506C12.3914 1.67516 12.6379 1.83354 12.6619 2.07856L12.8714 4.21252C12.8872 4.37356 13.003 4.5071 13.1601 4.54567L15.2425 5.05673C15.4816 5.11541 15.6033 5.38189 15.4911 5.60102L14.5136 7.50949C14.4399 7.65351 14.465 7.82841 14.5764 7.94582L16.0519 9.5016C16.2213 9.68023 16.1796 9.9702 15.9668 10.0939L14.1127 11.1709C13.9727 11.2522 13.8993 11.4129 13.9295 11.5719L14.3297 13.6784C14.3757 13.9203 14.1839 14.1417 13.9379 14.1307L11.7958 14.0343C11.6342 14.0271 11.4855 14.1226 11.425 14.2727L10.6228 16.2612C10.5307 16.4895 10.2496 16.572 10.0487 16.4297L8.29873 15.1906C8.16666 15.0971 7.98997 15.0971 7.85791 15.1906L6.10797 16.4297C5.90704 16.572 5.62596 16.4895 5.53385 16.2612L4.73163 14.2727C4.67109 14.1226 4.52244 14.0271 4.36079 14.0343L2.21873 14.1307C1.97278 14.1417 1.78094 13.9203 1.82689 13.6784L2.22708 11.5719C2.25728 11.4129 2.18388 11.2522 2.04396 11.1709L0.189865 10.0939C-0.0230204 9.9702 -0.0647105 9.68023 0.104711 9.5016L1.58026 7.94582C1.69161 7.82841 1.71676 7.65351 1.643 7.50949L0.66553 5.60102C0.553298 5.38189 0.674992 5.11541 0.914096 5.05673L2.99652 4.54567C3.15368 4.5071 3.26939 4.37356 3.2852 4.21252L3.4947 2.07856C3.51876 1.83354 3.7652 1.67516 3.99807 1.75506L6.02622 2.45097C6.17928 2.50349 6.34882 2.45371 6.44919 2.32678L7.77914 0.644839Z"
            fill="#3B85F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.86752 8.13004C5.16541 7.83215 5.6484 7.83215 5.9463 8.13004L7.54482 9.72857L11.2131 6.06029C11.511 5.76239 11.994 5.76239 12.2919 6.06029C12.5898 6.35819 12.5898 6.84117 12.2919 7.13907L7.54482 11.8861L4.86752 9.20882C4.56962 8.91093 4.56962 8.42794 4.86752 8.13004Z"
            fill="white"
        />
    </svg>
);