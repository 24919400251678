import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getAccessToken, loginUser, setUser, setUserData } from "@app/store/authSlice";
import { showFinishRegistrationForm } from "@app/store/layoutSlice";
import { AppState } from "@app/store/store";
import { baseQueryWithReauth } from "@shared/api/baseQueryWithReauth";
import { configApi } from "@shared/lib/configApi";
import { ProfileResponseScheme } from "../../../interfaces/profile.scheme";
import { User } from "../../../interfaces/user.interface";
import { CompanyProfile, CompanyProfileResponse } from "./types";

export const profileApi = createApi({
    reducerPath: "profileApi",
    tagTypes: ["PROFILE_TAG", "PROFILE_COMPANY_TAG"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getProfile: builder.query<User, any>({
            providesTags: ["PROFILE_TAG"],
            queryFn: async (args, api, extraOptions) => {
                const result: any = await baseQueryWithReauth(
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-token": args.accessToken
                        },
                        url: `${configApi.API_URL}/v1/profiles/me/full`
                    },
                    api,
                    extraOptions
                );

                const status = result.meta.response.status;
                const isSuccess = status === 200;

                const isValid = ProfileResponseScheme.safeParse(result?.data);

                let user = convertProfileApiDataToUser(result.data.data);

                if (user.isCompany) {
                    try {
                        const companyProfileResult = await api
                            .dispatch(profileApi.endpoints.getCompanyProfileShort.initiate())
                            .unwrap();

                        user = {
                            ...user,
                            companyName: companyProfileResult.name
                        };
                    } catch (error) {
                        api.dispatch(showFinishRegistrationForm());
                        console.error("Ошибка при получении companyName", error);
                    }
                }

                const shouldFinishRegistration = !user.isCompany && !result.data.data.firstName;
                if (shouldFinishRegistration) {
                    api.dispatch(showFinishRegistrationForm());
                }

                api.dispatch(setUser(user));
                return isSuccess && isValid
                    ? { data: user as User }
                    : {
                          error: isValid
                              ? (result.error as FetchBaseQueryError)
                              : { data: "Not valid server answer (Profile Scheme) ", status: 400 }
                      };
            },
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const result = await queryFulfilled;
                if (result.data) {
                    if (typeof window !== "undefined") {
                        setUserData(result.data);
                        dispatch(setUser(result.data));
                    }
                }
            }
        }),
        updateProfile: builder.mutation<User, any>({
            query: (payload) => {
                let TOKEN = "";
                if (typeof window !== "undefined") {
                    TOKEN = getAccessToken();
                }
                return {
                    url: `${configApi.API_URL}/v1/profiles/me`,
                    method: "PATCH",
                    body: {
                        ...payload,
                        middleName: "",
                        lastName: payload.lastName || "",
                        isNotificationEnabled: payload.isNotificationEnabled
                    },
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-token": TOKEN
                    }
                };
            },
            invalidatesTags: ["PROFILE_TAG"]
        }),
        getCompanyProfileShort: builder.query<CompanyProfile, void>({
            query: () => ({
                url: `${configApi.API_URL}/v1/company/profiles/me`
            }),
            transformResponse: (response: CompanyProfileResponse) => {
                return response.data;
            },
            providesTags: ["PROFILE_COMPANY_TAG"]
        }),
        getCompanyProfile: builder.query<CompanyProfile, void>({
            query: () => ({
                url: `${configApi.API_URL}/v1/company/profiles/me/full`
            }),
            transformResponse: (response: CompanyProfileResponse) => {
                return response.data;
            },
            providesTags: ["PROFILE_COMPANY_TAG"]
        }),
        updateCompanyProfile: builder.mutation<void, Partial<CompanyProfile>>({
            query: (payload) => ({
                url: `${configApi.API_URL}/v1/company/profiles/me`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: ["PROFILE_COMPANY_TAG"],
            async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
                await queryFulfilled;
                const currentUser = (getState() as AppState).auth.currentUser;
                if (currentUser?.isCompany && payload.name) {
                    const updatedUser = { ...currentUser, companyName: payload.name };
                    dispatch(setUser(updatedUser));
                    setUserData(updatedUser);
                }
            }
        }),
        createCompanyProfile: builder.mutation<void, Partial<CompanyProfile>>({
            query: (payload) => ({
                url: `${configApi.API_URL}/v1/company/profiles`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["PROFILE_COMPANY_TAG", "PROFILE_TAG"]
        }),
        deleteUser: builder.mutation<void, void>({
            query: () => ({
                url: `${configApi.API_URL}/v1/users/me`,
                method: "DELETE"
            }),
            invalidatesTags: ["PROFILE_TAG"]
        })
    })
});

export const changePassword = createAsyncThunk<
    void,
    { currentPassword: string; newPassword: string; repeatedPassword: string },
    { state: AppState; rejectValue: { message: string } }
>("profileApi/changePassword", async (payload, thunkAPI) => {
    const TOKEN = getAccessToken();
    const URL = `${configApi.API_URL}/v1/users/me/password`;
    const result: any = await baseQueryWithReauth(
        {
            headers: {
                "Content-Type": "application/json",
                "X-Auth-token": TOKEN
            },
            url: URL,
            method: "PUT",
            body: {
                currentPassword: payload.currentPassword,
                newPassword: payload.newPassword,
                repeatedPassword: payload.repeatedPassword
            }
        },
        {
            ...thunkAPI,
            endpoint: URL,
            type: "mutation"
        },
        {}
    );

    const status = result.meta.response.status;
    const isSuccess = status === 200;

    if (!isSuccess) {
        const { data } = result?.error || {};
        let message = data?.message || "Ошибка при изменении пароля";
        if (data?.message === "Ошибка валидации пароля" && data?.data?.errors?.length) {
            message = data.data.errors.join("\n");
        }
        return thunkAPI.rejectWithValue({ message });
    }

    try {
        const email = thunkAPI.getState().auth.currentUser?.email;
        const loginPayload = {
            email,
            password: payload.newPassword
        };

        const loginResult = await thunkAPI.dispatch(loginUser(loginPayload));

        if (loginUser.rejected.match(loginResult)) {
            throw new Error(
                loginResult.payload?.message || "Ошибка при авторизации после смены пароля"
            );
        }
    } catch (error: unknown) {
        const errorMessage = error instanceof Error ? error.message : "Неизвестная ошибка";
        return thunkAPI.rejectWithValue({ message: errorMessage });
    }

    return result.data;
});

export const convertProfileApiDataToUser = (data: any): User => {
    return {
        id: data.userUuid,
        name: data.firstName,
        lastName: data.lastName,
        email: data.user.email,
        phone: data.user.phone,
        isEmailVerified: data.user.isEmailVerified,
        isPhoneVerified: data.user.isPhoneVerified,
        isNotificationEnabled: data.isNotificationEnabled,
        isCompany: data.user.isCompany
    };
};

export const {
    useGetProfileQuery,
    useUpdateProfileMutation,
    useUpdateCompanyProfileMutation,
    useGetCompanyProfileQuery,
    useCreateCompanyProfileMutation,
    useDeleteUserMutation
} = profileApi;