import { createListenerMiddleware, ListenerEffectAPI, TypedStartListening } from "@reduxjs/toolkit";
import { authByOAuthTelegram, authByOAuthToken, fastLogin, loginUser } from "@app/store/authSlice";
import { mergeFavorites, offersApi } from "@app/store/offersApi";
import { AppDispatch, AppState } from "@app/store/store";

export const loginListenerMiddleware = createListenerMiddleware();

export type LoginStartListening = TypedStartListening<AppState, AppDispatch>;

const startListening = loginListenerMiddleware.startListening as LoginStartListening;

startListening({
    actionCreator: loginUser.fulfilled,
    effect: async (_, api) => {
        await handleLoginEvent(api);
    }
});

startListening({
    actionCreator: fastLogin.fulfilled,
    effect: async (_, api) => {
        await handleLoginEvent(api);
    }
});

startListening({
    actionCreator: authByOAuthToken.fulfilled,
    effect: async (_, api) => {
        await handleLoginEvent(api);
    }
});

startListening({
    actionCreator: authByOAuthTelegram.fulfilled,
    effect: async (_, api) => {
        await handleLoginEvent(api);
    }
});

async function handleLoginEvent(api: ListenerEffectAPI<AppState, AppDispatch>) {
    await api.dispatch(mergeFavorites());
    api.dispatch(offersApi.util.invalidateTags(["Favorites"]));
}