import { filteredCities } from "./constants";
import { CityItem } from "./types";

export const getCityCenterPoint = (city: string | undefined): [number, number] | undefined => {
    if (!city) return;

    const formattedCity = city.toLowerCase();
    const foundCity = filteredCities.find(
        (cityItem: CityItem) => cityItem.name.toLowerCase() === formattedCity
    );

    return foundCity?.coords;
};