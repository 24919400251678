import { CURRENT_YEAR, START_YEAR_PRODUCTION } from "@shared/config/Date";
import Car from "@shared/lib/interfaces/car.interface";
import { numberToRoman } from "@shared/lib/utils";

export const formatGeneration = (offer: Car) => {
    const {
        generationName,
        generationNumber,
        generationRestyling,
        generationStartedAt,
        generationEndedAt
    } = offer;

    const validStartedAt =
        generationStartedAt && generationStartedAt >= START_YEAR_PRODUCTION
            ? generationStartedAt
            : undefined;
    const validEndedAt =
        generationEndedAt && generationEndedAt >= START_YEAR_PRODUCTION
            ? generationEndedAt
            : CURRENT_YEAR;

    const period = validStartedAt ? `${validStartedAt} - ${validEndedAt}` : "";
    const generationNum = generationNumber ? numberToRoman(generationNumber) : "";
    const restyle = (generationRestyling || 0) > 0 ? `${generationRestyling} Рестайлинг` : "";

    const parts = [period, generationNum, generationName, restyle].filter(Boolean).join(" ");

    return parts.trim();
};